<template >
  <div class="containter">
    <div class="headerFixed">
      <div class="daohangLine">
        <img src="./imgs/mysj_logo.png" class="mysj_logo_img" />
        <div class="openOrCloseArea">
          <img
            v-if="!openMenu"
            @click="changeOpenMenu(true)"
            src="./imgs/openMenu.png"
            style="width:100%;height:100%;"
          />
          <img
            v-if="openMenu"
            @click="changeOpenMenu(false)"
            src="./imgs/cha.jpg"
            style="width:100%;height:100%;"
          />
        </div>
      </div>
    </div>
    <van-popup
      v-model="openMenu"
      position="top"
      :style="{ height: '10rem',backgroundColor:'#1E1E1E' }"
    >
      <div
        v-for="item in openMenuList"
        :key="item.id"
        @click="locationToPlace(item.id)"
        class="openMenuTabItem"
      >
        <div class="openMenuTabItemTitle">
          <div class="openMenuTabItemTitleLine">{{item.title}}</div>
          <div v-if="item.ohter" class="openMenuTabItemOtherLine">{{item.ohter}}</div>
        </div>
        <img src="./imgs/arrow_right.png" class="arrow_right" />
      </div>
      <div class="openMenuTabTelLine">售前咨询热线：400-663-7058</div>
      <div class="height110"></div>
    </van-popup>
    <div class="height50"></div>
    <div class="infoListArea" v-if="selectNum===1">
      <div class="height20"></div>
      <div class="infoListAreaTitle">湖州举行美丽城镇建设培训会 美云受邀分享智慧管家行动方</div>
      <div class="infoListAreaDate">2020-06-04</div>
      <div
        class="duanluo1"
      >5月28日下午，嘉兴市美丽城镇智慧管家建设培训会在南湖区七星街道举行。嘉兴市、各县（区、市）美丽城镇办相关负责人、各镇（街道）主要负责人参加了培训会。</div>
      <img src="./imgs/detailPage/detail_1_1.png" class="infoListAreaImg" />
      <div
        class="duanluo1"
      >会上，相关领导发表了重要讲话，其中湖州市建设局副局长金宁特别指出，“高水平开启新时代美丽浙江建设新征程，把绿水青山建得更美，把金山银山做得更大，让绿色成为浙江发展最动人的色彩，努力把浙江建设成为展示习近平生态文明思想和美丽中国建设成果的重要窗口。我市44个样本镇要全面开展美丽城镇建设工作，到2022年达到美丽城镇建设要求，计划用三年的时间，全面完成美丽城镇建设，将“在湖州看见美丽中国”城市品牌唱响全国，成为向世界展示“美丽中国”建设成果的重要窗口之一。"</div>
      <img src="./imgs/detailPage/detail_1_2.png" class="infoListAreaImg" />
      <div
        class="duanluo1"
      >会上，相关领导发表了重要讲话，其中湖州市建设局副局长金宁特别指出，“高水平开启新时代美丽浙江建设新征程，把绿水青山建得更美，把金山银山做得更大，让绿色成为浙江发展最动人的色彩，努力把浙江建设成为展示习近平生态文明思想和美丽中国建设成果的重要窗口。我市44个样本镇要全面开展美丽城镇建设工作，到2022年达到美丽城镇建设要求，计划用三年的时间，全面完成美丽城镇建设，将“在湖州看见美丽中国”城市品牌唱响全国，成为向世界展示“美丽中国”建设成果的重要窗口之一。"</div>
      <img src="./imgs/detailPage/detail_1_3.png" class="infoListAreaImg" />
      <div
        class="duanluo1"
      >美云数据始终坚持将技术领先与业务使能相结合，已为数十个乡镇、街道提供一体化智慧解决方案，将智慧管家打造成全新的智慧城镇管理服务平台，全面推动美丽城镇新基建数字化建设。</div>
      <div class="height20"></div>
    </div>
    <div class="infoListArea" v-if="selectNum===2">
      <div class="height20"></div>
      <div class="infoListAreaTitle">嘉兴举行美丽城镇智慧管家建设培训会 美云为南湖建设展示</div>
      <div class="infoListAreaDate">2020-05-30</div>
      <div
        class="duanluo1"
      >5月28日下午，嘉兴市美丽城镇智慧管家建设培训会在南湖区七星街道举行。嘉兴市、各县（区、市）美丽城镇办相关负责人、各镇（街道）主要负责人参加了培训会。</div>
      <div class="duanluo1">在会议开始前半小时，参会人员就陆陆续续地到达现场，签到后我们为每一位到场人员发放了口罩，最后我们统计了人数，签到率竟然接近100%！</div>
      <img src="./imgs/detailPage/detail_2_1.png" class="infoListAreaImg" />
      <div
        class="duanluo1"
      >会议开始后，领导发表了重要讲话，其中嘉兴市建设局副局长金洪良特别指出，“美丽城镇智慧管家的建设是综合基础设施管理、智慧文旅等各领域的智慧生态圈，开展美丽城镇智慧管家培训是推动工作理念、工作方式方法转变的重要一环。”</div>
      <div
        class="duanluo1"
      >其实早在今年3月，省美丽城镇办公布的《关于全省美丽城镇建设工作情况的汇报》中就特别提出了要聚焦实效抓载体，做好包括“美丽城镇智慧管家”在内的六大行动。</div>
      <img src="./imgs/detailPage/detail_2_2.png" class="infoListAreaImg" />
      <div
        class="duanluo1"
      >美云数据讲解方案；在会议上，美云数据总监刘坤路为与会人员讲解了“美丽城镇智慧管家及应用和现代化治理解决方案”，随后产品负责人邵晨晨向大家展示了七星街道美丽城镇数字化建设的成果。在方案讲解结束后，各乡镇领导对于美云美丽城镇智慧管家给予了高度评价，并添加了美云微信，希望能有更进一步的了解。</div>
      <img src="./imgs/detailPage/detail_2_3.png" class="infoListAreaImg" />
      <div class="duanluo1">数字化是今年美丽城镇建设的趋势和新要求，七星街道已经巧用“智慧力量”，以智慧管家助推美丽城镇建设，为居民带来智慧新体验。</div>
      <div
        class="duanluo1"
      >美云数据始终坚持将技术领先与业务使能相结合，已为数十个乡镇、街道提供一体化智慧解决方案，将智慧管家打造成全新的智慧城镇管理服务平台，全面推动美丽城镇新基建数字化建设。</div>
      <div class="height20"></div>
    </div>
    <div class="infoListArea" v-if="selectNum===3">
      <div class="height20"></div>
      <div class="infoListAreaTitle">解密众多乡镇点赞的美丽城镇智慧管家</div>
      <div class="infoListAreaDate">2020-05-30</div>
      <div
        class="duanluo1"
      >美丽城镇智慧管家平台坚持以人为核心，围绕智慧化、数字化、信息化的价值坐标，构建以环境美、生活美、产业美、人文美、治理美五美创新为重点的集成业务系统。遵循安全、实用、普惠、开放和创新的建设原则。打造有归属感、舒适感、未来感的新型信息城镇功能单元，促进人的全面发展和社会共同进步，打响浙江省“三服务”工作新名片。</div>
      <img src="./imgs/detailPage/detail_3_1.png" class="infoListAreaImg" />
      <div class="infoImgTitle">美丽城镇智慧管家平台</div>
      <div
        class="duanluo1"
      >数据驾驶舱打破纵向业务系统的信息壁垒，通过对接多源数据，利用大数据分析技术，以直观、明了、简洁的可视化呈现方式，展现美丽城镇五美建设的各项指标，呈现美丽城镇五美建设的成果，让每一项决策都有数据支撑，综合构建了战略指挥中心和事件调度中心。</div>
      <img src="./imgs/detailPage/detail_3_2.png" class="infoListAreaImg" />
      <div class="infoImgTitle">数据驾驶舱</div>
      <div
        class="duanluo1"
      >美云数据讲解方案；在会议上，美云数据总监刘坤路为与会人员讲解了“美丽城镇智慧管家及应用和现代化治理解决方案”，随后产品负责人邵晨晨向大家展示了七星街道美丽城镇数字化建设的成果。在方案讲解结束后，各乡镇领导对于美云美丽城镇智慧管家给予了高度评价，并添加了美云微信，希望能有更进一步的了解。</div>
      <img src="./imgs/detailPage/detail_3_3.png" class="infoListAreaImg" />
      <div class="infoImgTitle">环境美—智慧垃圾分类</div>
      <div
        class="duanluo1"
      >通过二维码技术，建立一户一码实名制，以循序渐进、重在参与、全民参与的运营理念，提升群众参与垃圾分类的积极性，让群众养成由随手“扔”变成随手“分”的良好习惯，采用信息化技术结合智能化手段帮助政府解决垃圾分类问题，从源头改善垃圾乱扔、乱放的现象，逐步实现垃圾的资源化、减量化、无害化处理，开创美丽城镇环境美新局面。</div>
      <img src="./imgs/detailPage/detail_3_4.png" class="infoListAreaImg" />
      <div class="infoImgTitle">生活美—未来社区</div>
      <div
        class="duanluo1"
      >围绕人本化、生态化、数字化共建全生命周期的数字化生活服务平台，为社区居民提供平安、绿色、健康、智慧的生活方式。服务平台集成住户、车辆、房屋、企业、社区公共资源、设备、网络等资源，整理整合信息发布、处理、反馈、宣传、通知、活动等在内的一体化的整合运营。通过链接数字家庭与民政、医疗、教育、养老等社会公共服务，实现街道、企业、物业、社区居民等多级多端的互联互通，通过社区数据的积累，社区居民可实现邻里互助、全生命周期的教育和健康服务、创业服务、低碳生活、共享服务、智慧物业、社群活动和居民自治等，可以用最快捷的方式解决生活中遇到的各种问题。</div>
      <img src="./imgs/detailPage/detail_3_5.png" class="infoListAreaImg" />
      <div class="infoImgTitle">产业美—一企一档</div>
      <div
        class="duanluo1"
      >基于云计算、大数据中心的“互联网+企业服务”平台，整合乡镇资源，为企业提供丰富、便捷的服务，协助企业之间实现资源共享。企业咨询政策、办理事务、表达诉求更加便捷高效，让乡镇的发展动力更充沛。</div>
      <img src="./imgs/detailPage/detail_3_6.png" class="infoListAreaImg" />
      <div class="infoImgTitle">人文美—智慧党建</div>
      <div
        class="duanluo1"
      >以党建创新为方向，打造特色党建为目的，全面梳理党组织服务，并结合乡镇特色，建设服务党员、服务群众的相关内容。通过加强对党内关爱、旅游服务、文史服务、志愿服务等功能的系统集成，让党员群众有更多的获得感；除此之外，党建工作向移动智能终端延伸，党员可以随时随地参与组织生活、处理党务工作、交流互动等；智慧党建还打造了大屏党建展示内容，依托乡镇党群服务中心大屏和移动端小屏，全方位地展现党组织的工作情况和成果，及时宣传展示基层党建好声音、好做法、好典型；并对党员进行网格化管理，进一步提升党组织凝聚力和党员常态化管理水平，打通服务群众“最后一公里”。</div>
      <img src="./imgs/detailPage/detail_3_7.png" class="infoListAreaImg" />
      <div class="infoImgTitle">时空印相</div>
      <div class="duanluo1">将应用场景与AI、区块链技术相结合的场馆科技产品，以科技感、时尚感、年轻化的展现形式，吸引更多的年轻人走入场馆学习。</div>
      <img src="./imgs/detailPage/detail_3_8.png" class="infoListAreaImg" />
      <div class="infoImgTitle">人文美—智慧文旅</div>
      <div
        class="duanluo1"
      >通过移动互联网整合当地特色文旅资源，为游客提供景区介绍、VR全景、攻略制定、线路查询、气象状况、周边辅助的“游、食、住、行、购、娱”一站式服务。同时对游客数据进行客源地分析、人流量监控，促进文化旅游业向智慧化转型，打造智慧旅游新名片，推动城镇旅游经济高质量发展。为当地文旅主管部门进行文旅规划决策提供技术和数据支撑。</div>
      <img src="./imgs/detailPage/detail_3_9.png" class="infoListAreaImg" />
      <div class="infoImgTitle">治理美—社会治理</div>
      <div
        class="duanluo1"
      >以人员为纽带进行情报信息采集、分析、组织、研判和预警。针对重点关注人群，融合治安视频、旅馆住宿等海量数据，运用机器学习、风险识别等算法，深度挖掘对象关系、行为、落脚点等特征，进行科学建模，准确预警，快速定位。将社会治理模式从单向管理转向协同治理，维护社会安全稳定。</div>
      <img src="./imgs/detailPage/detail_3_10.png" class="infoListAreaImg" />
      <div class="infoImgTitle">治理美—智慧城管</div>
      <div
        class="duanluo1"
      >以高速物联平台技术为基础，构建集感知、分析、服务、指挥和监察为一体的智慧城管总体架构，针对出店经营、无证游商、车辆违停、垃圾偷倒等城市管理突出问题，以网格化单元为基础，实现事件自动发现、遥控告警、分发处置，过程追溯等的全方位管理，提高城市管理智慧化、文明化和专业化水平。</div>
      <div class="height20"></div>
    </div>
    <div class="infoListArea" v-if="selectNum===4">
      <div class="height20"></div>
      <div class="infoListAreaTitle">美云数据宣传片首公开：智慧美丽城镇 拥抱无限可能</div>
      <div class="infoListAreaDate">2020-05-26</div>
      <div class="videoArea">
        <!-- <video
          style="width:100%;height:100%;"
          src="./imgs/detailPage/mgsj_video.mp4"
          controls="controls"
        >您的浏览器不支持 video 标签。</video>-->
        <img
          v-if="!videoPlay"
          @click="playVideo"
          src="./imgs/detailPage/video_show.png"
          class="videoAreaImg"
        />
        <video style="width:100%;height:100%;" ref="video1" id="video1" controls>
          <source
            src="https://test-mobile-meicheng.oss-cn-hangzhou.aliyuncs.com/upload/video/meiyunshuju.mp4"
            type="video/mp4"
          />
        </video>
      </div>

      <div
        class="duanluo1"
      >杭州美云数据科技有限公司拥有近20年的数据处理和ICT领域经验，核心团队以阿里系和浙大系为主要构成。公司以行业领先的数字化转型技术与数据智能服务为核心，集成物联网、云计算、大数据、人工智能、区块链等新一代信息技术，为政府提供一体化政务服务解决方案。</div>
      <div
        class="duanluo1"
      >公司的核心业务为智慧城市顶层设计、智慧城市应用开发、数字城镇、智慧园区的PASS平台、政务服务标准化SAAS软件和增值定制化解决方案。公司基于大数据引擎，通过大规模机器学习和深度学习等技术，实现数据智能，并通过可视化技术呈现，服务于政务、城管、公安、医疗、旅游等行业。</div>
      <div class="duanluo1">美云数据以实现中国梦为愿景，始终坚持将技术领先与业务使能相结合，让智能、便捷的数字生活普惠世界。</div>
      <div class="height20"></div>
    </div>
    <div class="lastArea">
      <div class="fourServerArea">
        <div class="fourServerItem margin24Left">
          <img src="./imgs/fourServe_1.png" class="fourServeImg" />
          <div class="fourServeFont">开通即用</div>
        </div>
        <div class="fourServerItem">
          <img src="./imgs/fourServe_2.png" class="fourServeImg" />
          <div class="fourServeFont">定制化服务</div>
        </div>
        <div class="fourServerItem">
          <img src="./imgs/fourServe_3.png" class="fourServeImg" />
          <div class="fourServeFont">1V1大客户服务</div>
        </div>
        <div class="fourServerItem margin24Right">
          <img src="./imgs/fourServe_4.png" class="fourServeImg" />
          <div class="fourServeFont">7*24小时服务</div>
        </div>
      </div>
      <div class="lastBottomBg">
        <div v-for="item in lastBottomBgList" :key="item" class="lastBottomBgItem">{{item}}</div>
      </div>
      <bottom-list
        :bottomList="bottomList"
        :bottomListSelect="bottomListSelect"
        @changeBottomListSelect="changeBottomListSelect"
      ></bottom-list>
      <div class="bottomMsgArea">
        <div class="height43"></div>
        <div class="bottomMsgTel">售前咨询热线：400-663-7058</div>
        <div class="greyLine"></div>
        <div class="bottomMsgCompany">
          Copyright © 杭州美云数据有限公司 All rights reserved
          浙ICP备20014233号
        </div>
      </div>
    </div>
    <banner-bottom></banner-bottom>
  </div>
</template>

<script>
import { Notify } from 'vant';
import BottomList from './bottomList';
import BannerBottom from './BannerBottom';

export default {
  name: 'InfoList',
  components: {
    BottomList,
    BannerBottom
  },
  data() {
    return {
      openMenu: false,
      selectNum: 1,
      bannersList: [
        {
          id: 1,
          linkUrl: require('./imgs/banner1@2x.png')
        },
        {
          id: 2,
          linkUrl: require('./imgs/banner2@2x.png')
        },
        {
          id: 3,
          linkUrl: require('./imgs/banner3@2x.png')
        }
      ],
      solveImgList: [
        { img: require('./imgs/solveIcon_1.png'), name: '数据接入' },
        { img: require('./imgs/solveIcon_2.png'), name: '清洗融合' },
        { img: require('./imgs/solveIcon_3.png'), name: '数据可视化' },
        { img: require('./imgs/solveIcon_4.png'), name: '态势分析' },
        { img: require('./imgs/solveIcon_5.png'), name: '决策依据' }
      ],
      selectSolveImg: 1,
      solvePlanSelectList: [
        {
          id: 1,
          img: require('./imgs/solvePlan_1.png'),
          name: '数据驾驶舱'
        },
        {
          id: 2,
          img: require('./imgs/solvePlan_2.png'),
          name: '智慧党建'
        },
        {
          id: 3,
          img: require('./imgs/solvePlan_3.png'),
          name: '垃圾分类'
        },
        {
          id: 4,
          img: require('./imgs/solvePlan_4.png'),
          name: '智慧文旅'
        },
        {
          id: 5,
          img: require('./imgs/solvePlan_5.png'),
          name: '时空印象'
        }
      ],
      solvePlanSelect: 0,
      buildSettingSelectList: [
        {
          id: 1,
          img: require('./imgs/buildSetting_1.png'),
          name: '国家政策引领',
          content:
            '党中央、国务院高度重视大数据发展，将大数据上升为我国国家战略之一。\n一、中共中央国务院关于深入推进城市执法体制改革改进城市管理工作的指导意见（中发〔2015〕37号）指出： 建立用数据说话、用数据决策、用数据管理、用数据创新的新机制。\n二、十九大之后不久，中共中央政治局就实施国家大数据战略进行第二次集体学习。 习近平总书记指出，善于获取数据、分析数据、运用数据，是领导干部做好工作的基本功。'
        },
        {
          id: 2,
          img: require('./imgs/buildSetting_2.png'),
          name: '地方政策不断出台',
          content:
            '中共浙江省委办公厅、浙江省人民政府办公厅印发《浙江省小城镇环境综合整治行动实施方案》，美丽城镇的建设主要目标是：到2020年，有100个左右的小城镇率先达到美丽城镇样板要求；到2020年，有300个左右小城镇达到美丽城镇样板要求，城乡融合发展体制初步建立；到2035年，城乡融合发展体制机制更加完善，全省小城镇高质量全面建成美丽城镇，同步高水平基本现代化。2019年9月，浙江发布《浙江省美丽城镇建设指南》，其中指出打造美丽城镇的重要意义：建设新时代美丽城镇，是推进“八八战略”再深化、改革开放再出发作出的重大部署，也是践行初心使命、实现人民对美好生活向往的实际行动。'
        },
        {
          id: 3,
          img: require('./imgs/buildSetting_3.png'),
          name: '城镇发展必然趋势',
          content:
            '随着美丽城镇管理工作的不断推进，数字化建设平台积累的数据不断增多，相关业务口系统成熟度不断提高，运用大数据技术实现城镇管理的决策科学化、治理精准化、服务高效化，是城镇管理发展的必然趋势！'
        }
      ],
      buildSettingSelect: 0,
      serverSupportList: [
        {
          id: 1,
          linkUrl: require('./imgs/serverSupport_1.png')
        },
        {
          id: 2,
          linkUrl: require('./imgs/serverSupport_2.png')
        },
        {
          id: 3,
          linkUrl: require('./imgs/serverSupport_3.png')
        },
        {
          id: 4,
          linkUrl: require('./imgs/serverSupport_4.png')
        }
      ],
      serverSupportShowList: [
        {
          id: 1,
          img: require('./imgs/serSupportIcon_1.png'),
          name: '多端协同'
        },
        {
          id: 2,
          img: require('./imgs/serSupportIcon_2.png'),
          name: '轻量化交付'
        },
        {
          id: 3,
          img: require('./imgs/serSupportIcon_3.png'),
          name: '流畅操作体验'
        }
      ],
      mysjData:
        '      杭州美云数据科技有限公司是一家大数据，AI，5G，人工智能的政务综合解决方案的提供商。主要从事新型智慧城市顶层设计，大数据集成平台，通过数据智能技术，大数据深度学习与开放平台和可视化呈现技术，服务于政府，公安，综治，城管，医疗，旅游，园区等行业。我们坚持将技术领先与业务使能相结合，以技术让生活更美好为使命，以数据技术和商业平台实现中国梦为愿景，让智能、便捷的数字生活惠享世界。\n \n      美云团队拥有近20年的数据处理能力和ICT 领域经验，带来领先的数字化转型技术和数据智能服务。目前，美云数据业务范围主要包含数字城镇，智慧园区的PASS平台和综合政务SAAS标准软件和增值定制解决方案，其中，形成了在通信软件、运营服务、云计算、大数据、人工智能、互联网架构等领域的核心竞争力。',
      lastBottomBgList: [
        '全方位产品解读',
        '专业技术咨询',
        '成功案例分享',
        '成熟解决方案'
      ],
      bottomListSelect: 0,
      bottomList: [
        {
          id: 1,
          name: '基础应用',
          list: ['组织架构', '数据申报', '管理工作台']
        },
        {
          id: 2,
          name: '环境美应用',
          list: ['智能排水', '垃圾分类', '智慧公厕', '智慧停车', '智慧消防']
        },
        {
          id: 3,
          name: '生活美应用',
          list: ['智慧路灯', '智慧医疗', '智慧养老', '智慧校园', '邻里互助']
        },
        {
          id: 4,
          name: '产业美应用',
          list: ['智慧物流', '智慧工业', '智慧农业', '产业集群', '一企一档']
        },
        {
          id: 5,
          name: '人文美应用',
          list: ['智慧景区', '智慧文旅', '智慧党建', '小区改造', '绿化管理']
        },
        {
          id: 6,
          name: '治理美应用',
          list: ['智慧治理', '智慧城管', '智慧小区', '智慧安防', '智慧物业']
        }
      ],
      openMenuList: [
        {
          id: 1,
          title: '解决方案',
          ohter: '让每个决策都有数据支撑'
        },
        {
          id: 2,
          title: '建设背景',
          ohter: '全新智慧城镇管理服务平台'
        },
        {
          id: 3,
          title: '服务支持',
          ohter: '提升智能化决策能力'
        },
        {
          id: 4,
          title: '美云数据'
        },
        {
          id: 5,
          title: '合作数据'
        }
      ],
      // 热点资讯的四张图
      informationList: [
        {
          id: 1,
          img: require('./imgs/infoPageList1.png'),
          title: '湖州举行美丽城镇建设培训会 美云受邀分享智慧管家行动方案',
          date: '2020-06-04'
        },
        {
          id: 2,
          img: require('./imgs/infoPageList2.png'),
          title: '嘉兴举行美丽城镇智慧管家建设培训会 美云为南湖建设展示',
          date: '2020-05-30'
        },
        {
          id: 3,
          img: require('./imgs/infoPageList3.png'),
          title: '解密众多乡镇点赞的美丽城镇智慧管家',
          date: '2020-05-30'
        },
        {
          id: 4,
          img: require('./imgs/infoPageList4.png'),
          title: '美云数据宣传片首公开：智慧美丽城镇 拥抱无限可能',
          date: '2020-05-26'
        }
      ],
      videoPlay: false
    };
  },
  watch: {},
  mounted() {
    this.$nextTick(function() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    });
  },
  created() {
    const num = this.$route.query.id || 0;
    if (num) {
      this.selectNum = Number(num);
    }
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    // fgsdgsd
    changeOpenMenu(value) {
      console.log(value);
      this.openMenu = value;
    },
    // 定位到具体位置
    locationToPlace(num) {
      this.solvePlanSelect = 0;
      this.buildSettingSelect = 0;
      this.bottomListSelect = 0;
      this.$router.push({ name: 'index', query: { id: num } });
      this.openMenu = false;
    },
    // 打开banner图的链接
    openBannerPage(url) {
      window.open(url);
    },
    // 解决方案的图的点击轮换
    solvePlanTurnRightOrLeft(direction) {
      if (direction === 'left') {
        if (this.selectSolveImg === 1) {
          this.selectSolveImg = 5;
        } else {
          this.selectSolveImg -= 1;
        }
      } else {
        if (this.selectSolveImg === 5) {
          this.selectSolveImg = 1;
        } else {
          this.selectSolveImg += 1;
        }
      }
    },
    changeBottomListSelect(value) {
      this.bottomListSelect = Number(value);
    },
    // 播放视频
    playVideo() {
      var myVideo = document.getElementById('video1');
      myVideo.play();
      // console.log(this.$refs.video1);
      // this.$refs.video1.play();
      this.videoPlay = true;
    }
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/37.5;

/deep/ .van-collapse-item__content {
  padding: 0px;
}
/deep/ .van-cell {
  padding: 12 * $px 0.42667rem;
  color: #333333;
}
/deep/ .van-collapse-item__title--expanded {
  color: #ffa500;
}
/deep/ .van-popup--top {
  top: 50 * $px;
  left: 0;
  width: 100%;
}
.containter {
  width: 100%;
}
.headerFixed {
  width: 100%;
  height: 50 * $px;
  background-color: #ffffff;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 5000;
}
.titleLine {
  width: 100%;
  height: 44 * $px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  font-size: 18 * $px;
}
.daohangLine {
  width: 100%;
  height: 50 * $px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mysj_logo_img {
  width: 107 * $px;
  height: 32.65 * $px;
  margin-left: 24 * $px;
}
.openOrCloseArea {
  width: 24 * $px;
  height: 24 * $px;
  margin-right: 28 * $px;
  display: flex;
}
.height94 {
  width: 100%;
  height: 94 * $px;
}
.height50 {
  width: 100%;
  height: 50 * $px;
}
.openMenuTabItem {
  width: 100%;
  height: 93 * $px;
  border-bottom: #333333 0.6 * $px solid;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bannerArea {
  width: 100%;
  height: 190 * $px;
  overflow: hidden;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20 * $px;
  line-height: 190 * $px;
  text-align: center;
  height: 100%;
  background-color: rgb(244, 215, 219);
}
.bannerImg {
  height: 190 * $px;
}
.solvePlanTab {
  width: 100%;
  height: 227 * $px;
}
.solvePlanTabTitle {
  width: 100%;
  height: 28 * $px;
  margin-top: 24 * $px;
  font-size: 20 * $px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.solvePlanTabSmallTitle {
  width: 100%;
  height: 17 * $px;
  margin-top: 6 * $px;
  font-size: 12 * $px;
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.solvePlanLunList {
  width: 100%;
  height: 128 * $px;
  margin-top: 16 * $px;
  display: flex;
  justify-content: space-between;
}
.solvePlanLunListLeft {
  width: 36 * $px;
  height: 36 * $px;
  margin-left: 26 * $px;
  margin-top: 32 * $px;
  display: flex;
}
.solvePlanLunListRight {
  width: 36 * $px;
  height: 36 * $px;
  margin-right: 26 * $px;
  margin-top: 32 * $px;
  display: flex;
}
.solvePlanLunCenter {
  width: 100 * $px;
  height: 128 * $px;
}
.solvePlanLunCenterImg {
  width: 100 * $px;
  height: 100 * $px;
}
.solvePlanLunCenterFont {
  width: 100%;
  height: 20 * $px;
  margin-top: 4 * $px;
  font-size: 14 * $px;
  color: #ffa500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.baseLine {
  width: 100%;
  height: 1 * $px;
  background-color: #eeeeee;
}
.solvePlanSelectImgArea {
  width: 100%;
  height: 174 * $px;
  position: relative;
}
.solvePlanSelectImg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}
.solvePlanSelectImgAreaDetail {
  width: 84 * $px;
  height: 32 * $px;
  position: absolute;
  border: #ffffff 1 * $px solid;
  border-radius: 2 * $px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14 * $px;
  top: 94 * $px;
  left: 24 * $px;
}
.divideLine {
  width: 100%;
  height: 10 * $px;
  background-color: #f5f5f5;
}
.height24 {
  width: 100%;
  height: 24 * $px;
}
.buildSettingTitle {
  width: 100%;
  height: 28 * $px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20 * $px;
  color: #000000;
}
.buildSettingSmallTitle {
  width: 100%;
  height: 17 * $px;
  color: #999999;
  font-size: 12 * $px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6 * $px;
}
.height16 {
  width: 100%;
  height: 16 * $px;
}
.buildSettingSelectimg {
  width: 359 * $px;
  height: 240 * $px;
  margin-left: 8 * $px;
}
.buildSettingSelectContent {
  width: 327 * $px;
  font-size: #666666;
  font-size: 12 * $px;
  line-height: 150%;
  white-space: pre-wrap;
  margin: 0 auto;
}
.serverSupportImg {
  width: 100%;
  height: 140 * $px;
  overflow-x: scroll;
}
.serverSupportImgList {
  width: 1268 * $px;
  height: 100%;
}
.serverSupportLunBoImg {
  width: 300 * $px;
  height: 140 * $px;
  margin-left: 17 * $px;
}
.serverSupportShowTab {
  width: 100%;
  height: 136 * $px;
  display: flex;
  justify-content: center;
}
.serverSupportShowTabItem {
  width: 118 * $px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.serverSupportShowTabItemImg {
  width: 48 * $px;
  height: 48 * $px;
  margin-top: 32 * $px;
}
.serverSupportShowTabItemFont {
  height: 20 * $px;
  width: 100%;
  color: #333333;
  font-size: 14 * $px;
  margin-top: 12 * $px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mysj-area {
  width: 100%;
  height: 420 * $px;
  background: url(./imgs/mysj_bg.png) no-repeat;
  background-size: 100% 100%;
}
.mysj-area-title {
  width: 100%;
  height: 28 * $px;
  font-size: 20 * $px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mysj-p {
  width: 327 * $px;
  margin: 24 * $px auto;
  color: #ffffff;
  font-size: 12 * $px;
  white-space: pre-wrap;
  line-height: 150%;
}
.couperationArea {
  width: 100%;
  height: 338 * $px;
}
.coopearationImg {
  width: 100%;
  height: 260 * $px;
  margin-top: 12 * $px;
}
.lastArea {
  width: 100%;
  background-color: #1e1e1e;
}
.fourServerArea {
  padding-left: 9.5 * $px;
  padding-right: 9.5 * $px;
  height: 130 * $px;
  display: flex;
  justify-content: space-between;
}
.fourServerItem {
  width: 60 * $px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fourServeImg {
  width: 44 * $px;
  height: 44 * $px;
  margin-top: 24 * $px;
}
.fourServeFont {
  width: 100%;
  text-align: center;
  line-height: 150%;
  font-size: 12 * $px;
  color: #ffffff;
  margin-top: 5 * $px;
}
.margin24Left {
  margin-left: 24 * $px;
}
.margin24Right {
  margin-right: 24 * $px;
}
.lastBottomBg {
  width: 100%;
  height: 150 * $px;
  background: url(./imgs/lastBottomBg.png) no-repeat;
  background-size: 100% 100%;
}
.lastBottomBgItem {
  width: 50%;
  height: 22 * $px;
  margin-top: 32 * $px;
  margin-bottom: 10 * $px;
  float: left;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14 * $px;
}
.bottomMsgArea {
  width: 100%;
  height: 222 * $px;
}
.bottomMsgTel {
  width: 343 * $px;
  height: 48 * $px;
  background-color: #ffffff;
  font-size: 18 * $px;
  color: #333333;
  border-radius: 4 * $px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
}
.greyLine {
  width: 327 * $px;
  height: 1 * $px;
  background-color: #333333;
  margin: 24 * $px auto 14 * $px;
}
.height43 {
  height: 43 * $px;
  width: 100%;
}
.bottomMsgCompany {
  width: 250 * $px;
  margin: 0 auto;
  line-height: 150%;
  text-align: center;
  font-size: 10 * $px;
  color: #ffffff;
}
.openMenuTabItemTitle {
  width: 250 * $px;
  height: 100%;
  margin-left: 24 * $px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.arrow_right {
  width: 12.5 * $px;
  height: 12.5 * $px;
  margin-right: 27 * $px;
}
.openMenuTabItemTitleLine {
  color: #ffffff;
  font-size: 16 * $px;
}
.openMenuTabItemOtherLine {
  color: rgb(64, 64, 64);
  font-size: 12 * $px;
  margin-top: 7 * $px;
}
.openMenuTabTelLine {
  margin-left: 24 * $px;
  margin-top: 77 * $px;
  color: #ffffff;
  font-size: 14 * $px;
}
.hotInformationTab {
  width: 100%;
  height: 240 * $px;
  background-color: #ffffff;
}
.greyLine10 {
  width: 100%;
  height: 10 * $px;
  background-color: #f5f5f5;
}
.hotInformationTabTitle {
  width: 100%;
  height: 28 * $px;
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 20 * $px;
  margin-top: 24 * $px;
  margin-bottom: 25 * $px;
}
.hotInformationTabTitleImg {
  width: 22 * $px;
  height: 22 * $px;
  margin-left: 6 * $px;
}
.hotInformationTabTitleFont {
  margin-left: 147 * $px;
}
.hotInformationTabListOut {
  width: 100%;
  margin-left: 16 * $px;
  height: 140 * $px;
  overflow-x: scroll;
}
.hotInformationTabList {
  width: 1272 * $px;
  height: 100%;
}
.hotInformationTabListImg {
  width: 300 * $px;
  height: 100%;
  margin-right: 18 * $px;
}
.infoListArea {
  width: 100%;
}
.height20 {
  height: 20 * $px;
  width: 100%;
}
.infoListAreaTitle {
  padding-left: 16 * $px;
  padding-right: 16 * $px;
  color: #333333;
  font-size: 17 * $px;
  line-height: 150%;
}
.infoListAreaDate {
  height: 22 * $px;
  margin-left: 16 * $px;
  margin-top: 8 * $px;
  color: #999999;
  font-size: 12 * $px;
  display: flex;
  align-items: center;
}
.duanluo1 {
  font-size: 14 * $px;
  color: #333333;
  line-height: 150%;
  width: 344 * $px;
  margin-top: 20 * $px;
  margin-left: 16 * $px;
  text-indent: 2em;
}
.infoListAreaImg {
  margin-top: 20 * $px;
  margin-left: 49 * $px;
  width: 276 * $px;
  height: 174 * $px;
}
.infoImgTitle {
  width: 100%;
  height: 22 * $px;
  margin-top: 8 * $px;
  text-align: center;
  line-height: 22 * $px;
  color: #333333;
  font-size: 16 * $px;
}
.videoArea {
  width: 276 * $px;
  height: 157 * $px;
  margin-top: 20 * $px;
  margin-left: 50 * $px;
  position: relative;
}
.videoAreaImg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}
.height110 {
  width: 100%;
  height: 110 * $px;
}
</style>





